import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ErrorAlert, SuccessAlert } from "../../../Model/Alert/SuccessAlert";
const Base_url = process.env.REACT_APP_API_URL + "/all/logs";
const POST_Base_Url =
  process.env.REACT_APP_API_URL + "/logs/bydate";

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% GET ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const GetLogApi = createAsyncThunk(
  "GetLogApi",
  async ({ Token }) => {
    const headers = {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.get(Base_url, { headers });

      if (response.data.status === false) {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('error', error)
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

  
export const GetAllLogRecordPOST = createAsyncThunk(
  "GetAllLogRecordPOST",
  async ({ Token, data }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.post(POST_Base_Url, data, {
        headers,
      });
      return response.data;
    } catch (error) {
      console.error("error", error);
      return error;
    }
  }
);


const LogPageSlice = createSlice({
  name: "LogPageSlice",
  initialState: {
    isLoading: false,
    status: null,
    message: "",
    LogData: [],
  },
  extraReducers: (builder) => {

    //%%%%%%%%%%%%%%%%%%%%%%% GET API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(GetLogApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetLogApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetLogApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.status = action.payload.status;
      state.message = action.payload.message;
      state.LogData = action.payload.status
        ? action.payload.result
        : [];
    });

     // %%%%%%%%%%%%%%%%%%%%%%%%%%%%% BOOKING GET RECORD API HANDLE %%%%%%%%%%%%%%%%%%%%%%%%%%
     builder.addCase(GetAllLogRecordPOST.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(GetAllLogRecordPOST.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetAllLogRecordPOST.fulfilled, (state, action) => {
      state.isLoading = true;
      state.CompanyData = action.payload.status
      ? action.payload.result
      : [];
    });
  },
});

export default LogPageSlice.reducer;
