import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ErrorAlert, SuccessAlert } from "../../../Model/Alert/SuccessAlert";
const Base_url = process.env.REACT_APP_API_URL + "/company";
const POST_Base_Url =
  process.env.REACT_APP_API_URL + "/companies/bydate";


  
export const GetAllCompanyRecordPOST = createAsyncThunk(
  "GetAllCompanyRecordPOST",
  async ({ Token, data }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.post(POST_Base_Url, data, {
        headers,
      });
      return response.data;
    } catch (error) {
      console.error("error", error);
      return error;
    }
  }
);
//%%%%%%%%%%%%%%%%%%%%%%%%%%%% POST CATEGORY API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const PostCompanyApi = createAsyncThunk(
  "PostCompanyApi",
  async ({ values, Token }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.post(Base_url, values, { headers });

      if (response.data.status === true) {
        SuccessAlert(response.data.message);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('error', error)
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data ;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% POST BULK CATEGORY API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const PostBulkCompanyApi = createAsyncThunk(
  "PostBulkCompanyApi",
  async ({ formdata, Token }) => {
    const headers = {
    "Content-type": "multipart/form-data",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.post(`${Base_url}/byfile`, formdata, { headers });

      if (response.data.status === true) {
        SuccessAlert(response.data.message);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('error', error)
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data ;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% GET ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const GetCompanyApi = createAsyncThunk(
  "GetCompanyApi",
  async ({ Token }) => {
    const headers = {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.get(Base_url, { headers });

      if (response.data.status === false) {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('error', error)
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% PUT ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const PutCompanyApi = createAsyncThunk(
  "PutCompanyApi",
  async ({ Token, values, id }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    const jsondata = JSON.stringify(values);

    try {
      const response = await axios.put(`${Base_url}/edit/${id}`, jsondata, {
        headers,
      });
      if (response.data.status === true) {
        SuccessAlert(response.data.message);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error('error', error)
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data ;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% DELETE ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const DeleteCompanyApi = createAsyncThunk(
  "DeleteCompanyApi",
  async ({ Token, id }) => {
    const headers = {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.delete(`${Base_url}/delete/${id}`, {
        headers,
      });
      if (response.data.status === true) {
        SuccessAlert(response.data.message);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data ;
    } catch (error) {
      console.error('error', error)
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data ;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% SINGLE ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// export const SingleCompanyApi = createAsyncThunk(
//   "SingleCompanyApi",
//   async ({ Token, values, id }) => {
//     const headers = {
//       "Content-type": "application/json",
//       Authorization: `Bearer ${Token}`,
//     };
//     const jsondata = JSON.stringify(values);
//     try {
//       const response = await axios.get(`${Base_url}/${id}`, jsondata, {
//         headers,
//       });
//       return response.data;
//     } catch (error) {
//       console.error('error', error)
//       if (error.response.status === 500) {
//         ErrorAlert(error.response.data.message);
//       }
//       return error.response.data;
//     }
//   }
// );

const CompanySlice = createSlice({
  name: "CompanySlice",
  initialState: {
    isLoading: false,
    status: null,
    message: "",
    CompanyData: [],
  },
  extraReducers: (builder) => {
    //%%%%%%%%%%%%%%%%%%%%%%% POST API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(PostCompanyApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PostCompanyApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PostCompanyApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;
    });

    //%%%%%%%%%%%%%%%%%%%%%%% POST BULK API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(PostBulkCompanyApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PostBulkCompanyApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PostBulkCompanyApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;
    });

    //%%%%%%%%%%%%%%%%%%%%%%% GET API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(GetCompanyApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetCompanyApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetCompanyApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.status = action.payload.status;
      state.message = action.payload.message;
      state.CompanyData = action.payload.status
        ? action.payload.result
        : [];
    });

    //%%%%%%%%%%%%%%%%%%%%%%% DELETE API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(DeleteCompanyApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(DeleteCompanyApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(DeleteCompanyApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;
    });

    //%%%%%%%%%%%%%%%%%%%%%%% PUT API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(PutCompanyApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PutCompanyApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PutCompanyApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.status = action.payload.status;
      state.message = action.payload.message;
    });

     // %%%%%%%%%%%%%%%%%%%%%%%%%%%%% BOOKING GET RECORD API HANDLE %%%%%%%%%%%%%%%%%%%%%%%%%%
     builder.addCase(GetAllCompanyRecordPOST.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(GetAllCompanyRecordPOST.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetAllCompanyRecordPOST.fulfilled, (state, action) => {
      state.isLoading = true;
      state.CompanyData = action.payload.status
      ? action.payload.result
      : [];
    });
  },
});

export default CompanySlice.reducer;
