import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { useEffect } from "react";
const Base_Url = process.env.REACT_APP_API_URL + "/booking";
const POST_Base_Url = process.env.REACT_APP_API_URL + "/all/booking";

export const GetAllBookingRecord = createAsyncThunk(
  "GetAllBookingRecord",
  async ({ Token, signal }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(Base_Url, { headers, signal });
      return response.data;
    } catch (error) {
      console.error("error", error);
      return error;
    }
  }
);

export const GetAllBookingRecordPOST = createAsyncThunk(
  "GetAllBookingRecordPOST",
  async ({ Token, data }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.post(POST_Base_Url, data, {
        headers,
      });
      return response.data;
    } catch (error) {
      console.error("error", error);
      return error;
    }
  }
);

const BookingrecordSlice = createSlice({
  name: "BookingrecordSlice",
  initialState: {
    status: null,
    BookingRecordData: [],
    isLoading: null,
    message: "",
  },
  extraReducers: (builder) => {
    // %%%%%%%%%%%%%%%%%%%%%%%%%%%%% BOOKING GET RECORD API HANDLE %%%%%%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(GetAllBookingRecord.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(GetAllBookingRecord.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetAllBookingRecord.fulfilled, (state, action) => {
      state.isLoading = true;
      state.BookingRecordData = action.payload.result;
    });

    // %%%%%%%%%%%%%%%%%%%%%%%%%%%%% BOOKING GET RECORD API HANDLE %%%%%%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(GetAllBookingRecordPOST.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(GetAllBookingRecordPOST.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetAllBookingRecordPOST.fulfilled, (state, action) => {
      state.isLoading = true;
      state.BookingRecordData = action.payload.result;
    });
  },
});

export default BookingrecordSlice.reducer;
