import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ErrorAlert, SuccessAlert } from "../../../Model/Alert/SuccessAlert";
const BaseUrl = process.env.REACT_APP_API_URL + "/billing";
const RoomInvoice_URL = process.env.REACT_APP_API_URL + "/room/invoice";
const Order_Invoice_URL = process.env.REACT_APP_API_URL + "/view/roomservice";
const Room_Servie_Base_URL =
  process.env.REACT_APP_API_URL + "/view/roomservice";
const Bulk_URL = process.env.REACT_APP_API_URL + "/bulk/invoice";
const Room_Invoice_payment_URL =
  process.env.REACT_APP_API_URL + "/payment/status/update";
const Order_Invoice_payment_URL =
  process.env.REACT_APP_API_URL + "/update/payment/status";
const Bul_Invoice_payment_URL =
  process.env.REACT_APP_API_URL + "/bulk/update/paymentstatus";
const GST_Invoice_payment_URL =
  process.env.REACT_APP_API_URL + "/update/gst/status";
const Invoice_status_complete_URL =
  process.env.REACT_APP_API_URL + "/update/status/completed";
const Invoice_cencel_URL = process.env.REACT_APP_API_URL + "/cancel/checkout";

// const OrderBase_url=process.env.REACT_APP_API_URL
const Edit_Base_URL = process.env.REACT_APP_API_URL + "/edit/bill";
const POST_Base_Url = process.env.REACT_APP_API_URL + "/all/booking";
// const DeleteUrl = process.env.REACT_APP_API_URL + "/delete/guest"

//%%%%%%%%%% GET USER API %%%%%%%%%%%%
export const GetAllBilling = createAsyncThunk(
  "GetAllBilling",
  async ({ Token, data }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };

    try {
      const response = await axios.post(BaseUrl, data, { headers });
      return response.data;
    } catch (error) {
      console.error("error", error);
      return error;
    }
  }
);

//%%%%%%%%%% GET Room Invoice API %%%%%%%%%%%%
export const RoomInvoiceAPI = createAsyncThunk(
  "RoomInvoiceAPI",
  async ({ Token, id }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };

    try {
      const response = await axios.get(`${RoomInvoice_URL}/${id}`, { headers });
      return response.data;
    } catch (error) {
      console.error("error", error);
      return error;
    }
  }
);

//%%%%%%%%%% GET ORDER API %%%%%%%%%%%%
export const OrderInvoiceAPI = createAsyncThunk(
  "OrderInvoiceAPI",
  async ({ Token, id }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };

    try {
      const response = await axios.get(`${Order_Invoice_URL}/${id}`, {
        headers,
      });
      return response.data;
    } catch (error) {
      console.error("error", error);
      return error;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%%% GET ROOM--SERVICE API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const GetRoomServiceApi = createAsyncThunk(
  "GetRoomServiceApi",
  async ({ Token }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.get(Room_Servie_Base_URL, { headers });
      return response.data;
    } catch (error) {
      console.error("error", error);
      return error;
    }
  }
);

//%%%%%%%%%% GET Bulk Invoice API %%%%%%%%%%%%
export const BulkInvoiceApi = createAsyncThunk(
  "BulkInvoiceApi",
  async ({ Token, id }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };

    try {
      const response = await axios.get(`${Bulk_URL}/${id}`, { headers });
      return response.data;
    } catch (error) {
      console.error("error", error);
      return error;
    }
  }
);

//%%%%%%%%%% Room  Invoice Pyamnent Status   API %%%%%%%%%%%%
export const RoomInvoicePayment_Status = createAsyncThunk(
  "RoomInvoicePayment_Status",
  async ({ Token, id }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };

    try {
      const response = await axios.post(
        `${Room_Invoice_payment_URL}/${id}`,
        {},
        { headers }
      );
      if (response.data.status === true) {
        SuccessAlert(response.data.message);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error("error", error);
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error;
    }
  }
);

//%%%%%%%%%% Room  Invoice Status complete  API %%%%%%%%%%%%
export const RoomInvoiceComplete_Status = createAsyncThunk(
  "RoomInvoiceComplete_Status",
  async ({ Token, id }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };

    try {
      const response = await axios.put(
        `${Invoice_status_complete_URL}/${id}`,
        {},
        { headers }
      );
      if (response.data.status === true) {
        SuccessAlert(response.data.message);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error("error", error);
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error;
    }
  }
);

//%%%%%%%%%% Room  Invoice Cancel  API %%%%%%%%%%%%
export const RoomInvoiceCancel = createAsyncThunk(
  "RoomInvoiceCancel",
  async ({ Token, id }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };

    try {
      const response = await axios.put(
        `${Invoice_cencel_URL}/${id}`,
        {},
        { headers }
      );
      if (response.data.status === true) {
        SuccessAlert(response.data.message);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error("error", error);
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error;
    }
  }
);

//%%%%%%%%%% Room  Invoice Pyamnent Status   API %%%%%%%%%%%%
export const GstRoomInvoicePayment_Status = createAsyncThunk(
  "GstRoomInvoicePayment_Status",
  async ({ Token, value, id }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };

    try {
      const response = await axios.put(
        `${GST_Invoice_payment_URL}/${id}`,
        value,
        { headers }
      );
      if (response.data.status === true) {
        SuccessAlert(response.data.message);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error("error", error);
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error;
    }
  }
);

//%%%%%%%%%% Order  Invoice Pyamnent Status   API %%%%%%%%%%%%
export const OrderInvoicePayment_Status = createAsyncThunk(
  "OrderInvoicePayment_Status",
  async ({ Token, id }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };

    try {
      const response = await axios.post(
        `${Order_Invoice_payment_URL}/${id}`,
        {},
        { headers }
      );
      if (response.data.status === true) {
        SuccessAlert(response.data.message);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error("error", error);
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error;
    }
  }
);

//%%%%%%%%%% Bulk  Invoice Pyamnent Status   API %%%%%%%%%%%%
export const BulInvoicePayment_Status = createAsyncThunk(
  "BulInvoicePayment_Status",
  async ({ Token, id }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };

    try {
      const response = await axios.post(
        `${Bul_Invoice_payment_URL}/${id}`,
        {},
        { headers }
      );
      if (response.data.status === true) {
        SuccessAlert(response.data.message);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error("error", error);
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error;
    }
  }
);

//%%%%%%%%%% Bill Edit   API %%%%%%%%%%%%
export const Edit_Bill = createAsyncThunk(
  "Edit_Bill",
  async ({ Token, values, id }) => {
    const headers = {
      Authorization: `Bearer ${Token}`,
    };

    try {
      const response = await axios.put(`${Edit_Base_URL}/${id}`, values, {
        headers,
      });
      if (response.data.status === true) {
        SuccessAlert(response.data.message);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error("error", error);
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error;
    }
  }
);

const BillingSlice = createSlice({
  name: "BillingSlice",
  initialState: {
    GetAllBillingData: [],
    RoomInvoiceData: {},
    OrderInvoiceData: [],
    AllBillingData: [],
    BulkData: [],
    iserror: null,
    isLoading: null,
    message: "",
  },

  extraReducers: (builder) => {
    // //%%%%%%%%%%%%%%%%%%% VIEW API HANDLE %%%%%%%%%%%%%%%%%%%%%
    builder.addCase(GetAllBilling.rejected, (state, action) => {
      state.iserror = "Client side Error";
    });
    builder.addCase(GetAllBilling.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetAllBilling.fulfilled, (state, action) => {
      state.isLoading = false;
      state.GetAllBillingData = action.payload.status
        ? action.payload.result
        : [];
    });

    // //%%%%%%%%%%%%%%%%%%% VIEW Single API HANDLE %%%%%%%%%%%%%%%%%%%%%
    builder.addCase(RoomInvoiceAPI.rejected, (state, action) => {
      state.iserror = "Client side Error";
    });
    builder.addCase(RoomInvoiceAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(RoomInvoiceAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.RoomInvoiceData = action.payload.result;
    });

    //%%%%%%%%%%%%%%%%%%% ORDER INVOICE API HANDLE %%%%%%%%%%%%%%%%%%%%%
    builder.addCase(OrderInvoiceAPI.rejected, (state, action) => {
      state.iserror = "Client side Error";
    });
    builder.addCase(OrderInvoiceAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(OrderInvoiceAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.OrderInvoiceData = action.payload.result;
    });

    //%%%%%%%%%%%%%%%%%%% BULK INVOICE API HANDLE %%%%%%%%%%%%%%%%%%%%%
    builder.addCase(BulkInvoiceApi.rejected, (state, action) => {
      state.iserror = "Client side Error";
    });
    builder.addCase(BulkInvoiceApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(BulkInvoiceApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.BulkData = action.payload.user;
    });

    //%%%%%%%%%%%%%%%%%% GET API HANDLE  %%%%%%%%%%%%%%%%%%%
    builder.addCase(GetRoomServiceApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetRoomServiceApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetRoomServiceApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.AllBillingData = action.payload.result;
    });

    // //%%%%%%%%%%%%%%%%%%% Room Invoice Pyamnent Status  API HANDLE %%%%%%%%%%%%%%%%%%%%%
    builder.addCase(RoomInvoicePayment_Status.rejected, (state, action) => {
      state.iserror = "Client side Error";
    });
    builder.addCase(RoomInvoicePayment_Status.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(RoomInvoicePayment_Status.fulfilled, (state, action) => {
      state.isLoading = false;
      // state.GetAllBillingData = action.payload.status?action.payload.result:[];
    });

    // //%%%%%%%%%%%%%%%%%%% Room Invoice Status complete  API HANDLE %%%%%%%%%%%%%%%%%%%%%
    builder.addCase(RoomInvoiceComplete_Status.rejected, (state, action) => {
      state.iserror = "Client side Error";
    });
    builder.addCase(RoomInvoiceComplete_Status.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(RoomInvoiceComplete_Status.fulfilled, (state, action) => {
      state.isLoading = false;
      // state.GetAllBillingData = action.payload.status?action.payload.result:[];
    });

    // //%%%%%%%%%%%%%%%%%%% Room Invoice cancel  API HANDLE %%%%%%%%%%%%%%%%%%%%%
    builder.addCase(RoomInvoiceCancel.rejected, (state, action) => {
      state.iserror = "Client side Error";
    });
    builder.addCase(RoomInvoiceCancel.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(RoomInvoiceCancel.fulfilled, (state, action) => {
      state.isLoading = false;
      // state.GetAllBillingData = action.payload.status?action.payload.result:[];
    });

    // //%%%%%%%%%%%%%%%%%%% GST Room Invoice Pyamnent Status  API HANDLE %%%%%%%%%%%%%%%%%%%%%
    builder.addCase(GstRoomInvoicePayment_Status.rejected, (state, action) => {
      state.iserror = "Client side Error";
    });
    builder.addCase(GstRoomInvoicePayment_Status.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GstRoomInvoicePayment_Status.fulfilled, (state, action) => {
      state.isLoading = false;
      // state.GetAllBillingData = action.payload.status?action.payload.result:[];
    });

    // //%%%%%%%%%%%%%%%%%%% Order Invoice Pyamnent Status  API HANDLE %%%%%%%%%%%%%%%%%%%%%
    builder.addCase(OrderInvoicePayment_Status.rejected, (state, action) => {
      state.iserror = "Client side Error";
    });
    builder.addCase(OrderInvoicePayment_Status.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(OrderInvoicePayment_Status.fulfilled, (state, action) => {
      state.isLoading = false;
      // state.GetAllBillingData = action.payload.status?action.payload.result:[];
    });

    // //%%%%%%%%%%%%%%%%%%% Bulk Invoice Pyamnent Status  API HANDLE %%%%%%%%%%%%%%%%%%%%%
    builder.addCase(BulInvoicePayment_Status.rejected, (state, action) => {
      state.iserror = "Client side Error";
    });
    builder.addCase(BulInvoicePayment_Status.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(BulInvoicePayment_Status.fulfilled, (state, action) => {
      state.isLoading = false;
      // state.GetAllBillingData = action.payload.status?action.payload.result:[];
    });

    // //%%%%%%%%%%%%%%%%%%% Bulk Invoice Pyamnent Status  API HANDLE %%%%%%%%%%%%%%%%%%%%%
    builder.addCase(Edit_Bill.rejected, (state, action) => {
      state.iserror = "Client side Error";
    });
    builder.addCase(Edit_Bill.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(Edit_Bill.fulfilled, (state, action) => {
      state.isLoading = false;
      // state.GetAllBillingData = action.payload.status?action.payload.result:[];
    });
  },
});

export default BillingSlice.reducer;
